
// @ts-nocheck


export const localeCodes =  [
  "en-US",
  "fr-FR",
  "es-ES",
  "pt-PT",
  "de-DE"
]

export const localeLoaders = {
  "en-US": [{ key: "../src/locales/en.json", load: () => import("../src/locales/en.json" /* webpackChunkName: "locale__app_src_locales_en_json" */), cache: true }],
  "fr-FR": [{ key: "../src/locales/fr.json", load: () => import("../src/locales/fr.json" /* webpackChunkName: "locale__app_src_locales_fr_json" */), cache: true }],
  "es-ES": [{ key: "../src/locales/es.json", load: () => import("../src/locales/es.json" /* webpackChunkName: "locale__app_src_locales_es_json" */), cache: true }],
  "pt-PT": [{ key: "../src/locales/pt.json", load: () => import("../src/locales/pt.json" /* webpackChunkName: "locale__app_src_locales_pt_json" */), cache: true }],
  "de-DE": [{ key: "../src/locales/de.json", load: () => import("../src/locales/de.json" /* webpackChunkName: "locale__app_src_locales_de_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "restructureDir": "src",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute",
    "alternateLinkCanonicalQueries": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en-US",
      "language": "en-US",
      "name": "English",
      "files": [
        "/app/src/locales/en.json"
      ]
    },
    {
      "code": "fr-FR",
      "language": "fr-FR",
      "name": "Français",
      "files": [
        "/app/src/locales/fr.json"
      ]
    },
    {
      "code": "es-ES",
      "language": "es-ES",
      "name": "Español",
      "files": [
        "/app/src/locales/es.json"
      ]
    },
    {
      "code": "pt-PT",
      "language": "pt-PT",
      "name": "Português",
      "files": [
        "/app/src/locales/pt.json"
      ]
    },
    {
      "code": "de-DE",
      "language": "de-DE",
      "name": "Deutsch",
      "files": [
        "/app/src/locales/de.json"
      ]
    }
  ],
  "defaultLocale": "en-US",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en-US",
    "language": "en-US",
    "name": "English",
    "files": [
      {
        "path": "/app/src/locales/en.json"
      }
    ]
  },
  {
    "code": "fr-FR",
    "language": "fr-FR",
    "name": "Français",
    "files": [
      {
        "path": "/app/src/locales/fr.json"
      }
    ]
  },
  {
    "code": "es-ES",
    "language": "es-ES",
    "name": "Español",
    "files": [
      {
        "path": "/app/src/locales/es.json"
      }
    ]
  },
  {
    "code": "pt-PT",
    "language": "pt-PT",
    "name": "Português",
    "files": [
      {
        "path": "/app/src/locales/pt.json"
      }
    ]
  },
  {
    "code": "de-DE",
    "language": "de-DE",
    "name": "Deutsch",
    "files": [
      {
        "path": "/app/src/locales/de.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
